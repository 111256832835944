.App {
  font-family: "Helvetica", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
h2 {
  font-family: "Helvetica", sans-serif;
}
body::after {
  content: "";
	background: url("https://i.imgur.com/lo0WAl1.png");
	background-size: cover;
  opacity: 0.2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

input {
  border-radius: 5px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
#logo {
  width: 300px;
}

#loginBox {
  display: flex;
  flex-direction: column;
  background: rgba(240, 240, 240, 0.9);
  padding: 30px;
  border-width: 1px;
  border-color: gray;
  border-radius: 10px;
  border-style: solid;
  max-width: 80%;
}
#loginBox > div {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  min-width: 100px;
  align-items: center;
}
#loginBox > div > span {
  padding-right: 5px;
}
#loginBox > div > input {
	min-width: 100px;
	padding: 5px;
}
#loginBox > button {
  padding: 8px;
  border-radius: 10px;
  margin-top: 5px;
}
#loginBox > button.positive {
  border: solid rgb(139, 206, 139) 2px;
  background: rgb(169, 241, 169);
}
#loginBox > button.negative {
  border: solid rgb(236, 109, 104) 2px;
  background: rgb(236, 86, 81);
}
.neutral {
	padding: 8px;
	border-radius: 10px;
	margin-top: 5px;
	border: solid rgb(173, 173, 173) 2px;
	background: rgb(173, 173, 173);
	color: white;
}
.errorTxt {
  text-align: center;
  color: red;
  padding: 5px;
  font-weight: 600;
}

.selectWrapper {
	width: 90%;
	min-width: 150px;
}



#loginBox > .contenedorTabla {
	display: flex;
	flex-direction: column;
}

.filaTabla {
	display: flex;
	flex-direction: row;
	min-width: 400px;
}

.categoriaTabla {
	width: 200px;
	text-align: right;
	padding-right: 10px;
	font-weight: 700;
}

.valorTabla {
	width: 200px;
	font-family: 'Roboto Mono', serif;
}